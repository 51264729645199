import { ProductHit } from '~/features/instant-search/types'

const mapSearchData = (item: ProductHit) => {
  return {
    objectId: item.objectID,
    id: item.id,
    title: item.title,
    handle: item.handle,
    tags: item.tags,
    productType: item.product_type,
    mainImage: {
      srcDesktopMedium: item.product_image,
      srcDesktop: item.product_image,
      originalSrc: item.product_image,
      srcMobileSmall: item.product_image,
      srcMobile: item.product_image,
    },
    cheapestVariant: null,
    highestVariant: null,
    price: item.price ? { amount: item.price } : null,
    compareAtPrice: item.compare_at_price ? { amount: item.compare_at_price } : null,
    variants: [
      {
        price: item.price,
        compareAtPrice: item.compare_at_price ? { amount: item.compare_at_price } : null,
        image: {
          srcDesktopMedium: item.product_image,
          srcDesktop: item.product_image,
          originalSrc: item.product_image,
        },
        selectedOptions: [
          {
            name: 'Color',
            value: item.option1,
          },
          {
            name: 'Size',
            value: item.option2,
          },
        ],
      },
    ],
  }
}

export { mapSearchData }
