/** @jsx jsx */
// @ts-ignore
import { jsx, Box, Grid } from 'theme-ui'
import {
  createRef,
  useEffect,
  ElementType,
  Fragment,
  Ref,
  forwardRef,
  RefObject,
  useMemo,
  useState,
} from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import type { InfiniteHitsProvided } from 'react-instantsearch-core'
import useIntersectionObserver from '~/hooks/use-intersection-observer'
import { ProductHit } from '~/features/instant-search/types'
import { LoadingIndicator } from './loaderIndicator'
import { useScrollEndBehavior } from '~/hooks/use-scroll-end'
import { debounce } from 'lodash'

type ChildrenArgs<Hit> = {
  hits: Hit[]
}

interface Props<Hit> extends InfiniteHitsProvided<Hit> {
  children: (args: ChildrenArgs<Hit>) => ElementType | ElementType<any> | ElementType[]
  container: Ref<HTMLDivElement>
}

const InfiniteHitsContainer = forwardRef<RefObject<HTMLDivElement>, Props<ProductHit>>(
  ({ hits, hasMore, refineNext, children, container, ...rest }, ref) => {
    const sentinel = createRef<HTMLDivElement>()
    const entry = useIntersectionObserver(sentinel, {})
    const [previousHits, setPreviousHits] = useState(hits.length)
    const hitsCount = useMemo(() => hits.length, [hits])
    const isEndReached = useScrollEndBehavior()

    useEffect(() => {
      if (entry?.isIntersecting && hasMore && hitsCount > previousHits) {
        refineNext()
        setPreviousHits(hitsCount)
      }
    }, [entry, hasMore])

    useEffect(() => {
      if (isEndReached && hasMore) {
        debounce(refineNext, 200)()
      }
    }, [isEndReached, hasMore, refineNext])

    return (
      <Fragment>
        <Grid
          as="section"
          role="feed"
          columns={[1, 2, 3]}
          gap={['gridGap1', null, 'gridGap3']}
          aria-label="Search results"
          aria-live="polite"
          aria-busy={false}
          ref={container}
          {...rest}
        >
          {children({
            hits,
          })}
        </Grid>
        <LoadingIndicator />
        <div ref={sentinel} id="sentinel" style={{ width: '100%', height: '1px' }} />
      </Fragment>
    )
  }
)

// @ts-ignore
export default connectInfiniteHits(InfiniteHitsContainer)
