import { FC, ReactElement } from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { ProductHit } from '~/features/instant-search/types'

interface Props extends StateResultsProvided<ProductHit> {
  children: (args: { total: number }) => ReactElement
}

// @ts-ignore
const StateTotalCount: FC<Props> = ({ searchResults, children }) => {
  return children({
    total: searchResults && searchResults.nbHits,
  })
}

// const compareProps = (prev: Props, next: Props) => {
//   if (!prev.searchResults || !next.searchResults) {
//     return true
//   }

//   return prev.searchResults.nbHits === next.searchResults.nbHits
// }

// @ts-ignore
export default StateTotalCount
