/** @jsx jsx */
// @ts-ignore
import { jsx, Text } from 'theme-ui'
import StateResults from '~/features/instant-search/state-results'

const SearchResults = () => (
  <StateResults>
    {/* @ts-ignore */}
    {({ hits, hasResults, isSearchStalled }) => {
      if (!hasResults) {
        return null
      }

      return (
        <Text aria-live="polite" aria-busy={isSearchStalled}>
          There are {hits} results
        </Text>
      )
    }}
  </StateResults>
)

export default SearchResults
