import { RefObject, useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  { threshold = 0.1, root = null, rootMargin = '300px', freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && entry?.intersectionRatio > 0.5 && freezeOnceVisible

  const updateEntry = useCallback(
    debounce(([entry]: IntersectionObserverEntry[]) => {
      setEntry(entry)
    }, 200),
    []
  )

  useEffect(() => {
    const node = elementRef?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    // Certifique-se de que o nó está no DOM antes de observar
    if (!node.isConnected) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef, threshold, root, rootMargin, frozen])

  return entry
}

export default useIntersectionObserver
