import React, { FC } from 'react'
import SearchList from '~/containers/search-list'
import CollectionLayout from '~/components/layout/collection-layout'

const SearchTemplate: FC = () => (
  <CollectionLayout title="Search results">
    <SearchList />
  </CollectionLayout>
)

export default SearchTemplate
