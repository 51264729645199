import { useEffect, useState, RefObject } from 'react'

export function useScrollEndBehavior(ref?: RefObject<HTMLDivElement> | null, threshold = 300) {
  const [isEndReached, setIsEndReached] = useState(false)

  const handleScroll = () => {
    const node = ref?.current || document.documentElement // Use document.documentElement for the entire page
    if (!node) return

    const { scrollTop, scrollHeight, clientHeight } = node

    if (scrollTop + clientHeight >= scrollHeight - threshold) {
      setIsEndReached(true)
    } else {
      setIsEndReached(false)
    }
  }

  useEffect(() => {
    if (ref?.current) {
      // If a specific ref (div) is provided, use its scroll listener
      const node = ref.current
      node.addEventListener('scroll', handleScroll)
      return () => node.removeEventListener('scroll', handleScroll)
    } else {
      // Attach scroll event to the window for body scrolling
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [ref])

  return isEndReached
}
