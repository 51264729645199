/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC, useEffect } from 'react'
import SearchTemplate from '~/templates/search'
import pintrk, { PinterestTrackedEvents } from '~/utils/marketing/pinterest'
import { useQueryParams } from '~/hooks/use-query-params'

type Props = {}

const Search: FC<Props> = props => {
  const { queryParams } = useQueryParams()

  const searchValue = queryParams && queryParams.search

  useEffect(() => {
    pintrk({
      event: PinterestTrackedEvents.Search,
      params: {
        search_query: searchValue,
      },
    })
  }, [])

  return <SearchTemplate {...props} />
}

export default Search
