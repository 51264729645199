/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import React, { createRef } from 'react'
import { Box } from 'theme-ui'

import SearchResults from '~/containers/search-list/search-results'

import { useQueryParams } from '~/hooks/use-query-params'

import InfiniteHitsContainer from '~/features/instant-search/infinite-hits-container'
import InstantSearchWrapper from '~/features/instant-search'
import StateTotalCount from '~/features/instant-search/state-total-count'
import SearchBox from '~/features/instant-search/search-box'
import Hit from '~/features/instant-search/hit'

import ProductItem from '~/components/product-item'
import { mapSearchData } from '~/containers/search-list/utils'

const SearchList = () => {
  const { queryParams } = useQueryParams()
  const container = createRef<HTMLDivElement>()

  const search: string = queryParams?.search as string

  return (
    <Box as="section" role="search" aria-label="Search page">
      <InstantSearchWrapper initialSearchValue={search ?? ``}>
        {/* @ts-ignore */}
        <SearchResults />
        <Box
          sx={{
            mt: 'gridGap3',
          }}
        >
          <SearchBox
            sx={{
              mb: 'gridGap3',
            }}
          />
          <InfiniteHitsContainer container={container}>
            {({ hits }) => {
              return hits.map((hit, index: number) => (
                // @ts-ignore
                <StateTotalCount>
                  {({ total }) => {
                    return (
                      <Hit
                        key={hit.objectID}
                        hit={hit}
                        label={hit.title}
                        aria-posinset={index + 1}
                        aria-setsize={total}
                        containerRef={container}
                      >
                        {/* @ts-ignore */}
                        <ProductItem
                          product={mapSearchData(hit)}
                          // @ts-ignore
                          imageSize="OVERHEAD"
                        />
                      </Hit>
                    )
                  }}
                </StateTotalCount>
              ))
            }}
          </InfiniteHitsContainer>
        </Box>
      </InstantSearchWrapper>
    </Box>
  )
}

export default SearchList
